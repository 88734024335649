.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;  
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.Login {
  margin: 10px !important;
  padding: 0 !important;
  border-radius: 4px !important;
}

.Login div {
  margin-right: 0 !important;
}
